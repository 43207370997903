jQuery( document ).ready(function($) {

  AOS.init({
    duration: 1000,
    once: true,
  })

  // Popup
  const allLinks = document.querySelectorAll('svg a')
  allLinks.forEach(link => {
    link.addEventListener('click', (e) => {
      e.preventDefault()
      const link = $(e.target).parent().attr('data-popup-link')
      const message = $(e.target).parent().attr('data-popup-message')
      $('.popup').addClass('active')
      $('.popup h3').html(message)
      $('.popup a').attr('href', link)
    })
  })

  $('.popup .close').click(() => {
    $('.popup').removeClass('active')
  })

  // Custom Popups
  $('.popup a').click((e) => {
    const targetHref = $(e.target).attr('href')

    // Video Popup
    if(targetHref.includes('youtube') && targetHref.includes('embed') || targetHref.includes('.mp4')){
      e.preventDefault() 
      $('.popup-video iframe').attr('src', targetHref)
      $('.popup-video').addClass('active')     
      if(targetHref.includes('.mp4')){
        $('.popup-video .download').attr('href', targetHref)
        $('.popup-video .download').show()
      }
    }
    // Images Popup
    if(targetHref.includes('popup-images-duo')){
      e.preventDefault() 
      $('.popup-images').addClass('active')     
    }

    // Image Popup
    if(targetHref.includes('popup-images-single')){
      e.preventDefault() 
      $('.popup-image').addClass('active')     
    }
  })

  $('.popup-video .close').click(() => {
    $('.popup-video').removeClass('active')
    $('.popup-video iframe').attr('src', '')
  })

  $('.popup-images .close').click(() => {
    $('.popup-images').removeClass('active')
  })

  $('.popup-image .close').click(() => {
    $('.popup-image').removeClass('active')
  })

  
  // Set background
  const desktopBg = document.querySelector('svg.desktop image')
  const mobileBg = document.querySelector('svg.mobile image')
  
  function setBackground(number){
    desktopBg.setAttribute('xlink:href', `./dist/img/bg/desktop/bg-desktop-${number}.jpg`)
    mobileBg.setAttribute('xlink:href', `./dist/img/bg/mobile/bg-mobile-${number}.jpg`)
  }
  // setBackground(12)

  

})